export const ChatIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <></>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.168 7.50033C14.168 10.7253 11.368 13.3337 7.91797 13.3337L7.14297 14.267L6.68464 14.817C6.29297 15.2837 5.54297 15.1836 5.28463 14.6253L4.16797 12.167C2.6513 11.1003 1.66797 9.40866 1.66797 7.50033C1.66797 4.27533 4.46797 1.66699 7.91797 1.66699C10.4346 1.66699 12.6096 3.05866 13.5846 5.05866C13.9596 5.80033 14.168 6.62533 14.168 7.50033Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3323 10.7169C18.3323 12.6252 17.349 14.3169 15.8323 15.3836L14.7156 17.8419C14.4573 18.4002 13.7073 18.5086 13.3156 18.0336L12.0823 16.5502C10.0656 16.5502 8.26563 15.6586 7.14062 14.2669L7.91562 13.3336C11.3656 13.3336 14.1656 10.7253 14.1656 7.50025C14.1656 6.62525 13.9573 5.80026 13.5823 5.05859C16.3073 5.68359 18.3323 7.98358 18.3323 10.7169Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83203 7.5H9.9987"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export const FeedIcon = ({ active }: { active?: boolean }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.565 16.6318C10.794 16.685 10.8149 16.9849 10.592 17.0592L9.27528 17.4926C5.96695 18.5592 4.22528 17.6676 3.15028 14.3592L2.08362 11.0676C1.01695 7.75924 1.90028 6.00924 5.20862 4.94258L5.47595 4.85405C5.87881 4.72063 6.2744 5.12599 6.16549 5.53616C6.13481 5.65171 6.10482 5.77052 6.07528 5.89258L5.25862 9.38424C4.34195 13.3092 5.68362 15.4759 9.60862 16.4092L10.565 16.6318Z"
      fill="#601E8A"
    />
    <path
      d="M14.3088 2.67444L12.9171 2.34944C10.1338 1.6911 8.47548 2.23277 7.50048 4.24944C7.25048 4.75777 7.05048 5.37444 6.88381 6.08277L6.06714 9.57444C5.25048 13.0578 6.32548 14.7744 9.80048 15.5994L11.2005 15.9328C11.6838 16.0494 12.1338 16.1244 12.5505 16.1578C15.1505 16.4078 16.5338 15.1911 17.2338 12.1828L18.0505 8.69944C18.8671 5.2161 17.8005 3.4911 14.3088 2.67444ZM12.7421 11.1078C12.6671 11.3911 12.4171 11.5744 12.1338 11.5744C12.0838 11.5744 12.0338 11.5661 11.9755 11.5578L9.55048 10.9411C9.21714 10.8578 9.01714 10.5161 9.10048 10.1828C9.18381 9.84944 9.52548 9.64944 9.85881 9.73277L12.2838 10.3494C12.6255 10.4328 12.8255 10.7744 12.7421 11.1078ZM15.1838 8.2911C15.1088 8.57444 14.8588 8.75777 14.5755 8.75777C14.5255 8.75777 14.4755 8.74944 14.4171 8.7411L10.3755 7.7161C10.0421 7.63277 9.84214 7.2911 9.92548 6.95777C10.0088 6.62444 10.3505 6.42444 10.6838 6.50777L14.7255 7.53277C15.0671 7.60777 15.2671 7.94944 15.1838 8.2911Z"
      fill="#601E8A"
    />
  </svg>
);
export const ConnectionsIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5013 1.66602C5.31797 1.66602 3.54297 3.44102 3.54297 5.62435C3.54297 7.76602 5.21797 9.49935 7.4013 9.57435C7.46797 9.56602 7.53464 9.56602 7.58464 9.57435C7.6013 9.57435 7.60963 9.57435 7.6263 9.57435C7.63463 9.57435 7.63463 9.57435 7.64297 9.57435C9.7763 9.49935 11.4513 7.76602 11.4596 5.62435C11.4596 3.44102 9.68463 1.66602 7.5013 1.66602Z"
        fill="#601E8A"
      />
      <path
        d="M11.7328 11.7914C9.40781 10.2414 5.61615 10.2414 3.27448 11.7914C2.21615 12.4997 1.63281 13.4581 1.63281 14.4831C1.63281 15.5081 2.21615 16.4581 3.26615 17.1581C4.43281 17.9414 5.96615 18.3331 7.49948 18.3331C9.03281 18.3331 10.5661 17.9414 11.7328 17.1581C12.7828 16.4497 13.3661 15.4997 13.3661 14.4664C13.3578 13.4414 12.7828 12.4914 11.7328 11.7914Z"
        fill="#601E8A"
      />
      <path
        d="M16.6578 6.11708C16.7911 7.73374 15.6411 9.15041 14.0495 9.34208C14.0411 9.34208 14.0411 9.34208 14.0328 9.34208H14.0078C13.9578 9.34208 13.9078 9.34207 13.8661 9.35874C13.0578 9.40041 12.3161 9.14207 11.7578 8.66707C12.6161 7.90041 13.1078 6.75041 13.0078 5.50041C12.9495 4.82541 12.7161 4.20874 12.3661 3.68374C12.6828 3.52541 13.0495 3.42541 13.4245 3.39208C15.0578 3.25041 16.5161 4.46708 16.6578 6.11708Z"
        fill="#601E8A"
      />
      <path
        d="M18.3268 13.8247C18.2602 14.633 17.7435 15.333 16.8768 15.808C16.0435 16.2663 14.9935 16.483 13.9518 16.458C14.5518 15.9163 14.9018 15.2413 14.9685 14.5247C15.0518 13.4913 14.5602 12.4997 13.5768 11.708C13.0185 11.2663 12.3685 10.9163 11.6602 10.658C13.5018 10.1247 15.8185 10.483 17.2435 11.633C18.0102 12.2497 18.4018 13.0247 18.3268 13.8247Z"
        fill="#601E8A"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63338 9.05841C7.55005 9.05008 7.45005 9.05008 7.35838 9.05841C5.37505 8.99175 3.80005 7.36675 3.80005 5.36675C3.80005 3.32508 5.45005 1.66675 7.50005 1.66675C9.54172 1.66675 11.2 3.32508 11.2 5.36675C11.1917 7.36675 9.61671 8.99175 7.63338 9.05841Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.675 3.33325C15.2916 3.33325 16.5917 4.64159 16.5917 6.24992C16.5917 7.82492 15.3417 9.10825 13.7833 9.16659C13.7167 9.15825 13.6417 9.15825 13.5667 9.16659"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.46672 12.1333C1.45006 13.4833 1.45006 15.6833 3.46672 17.0249C5.75839 18.5583 9.51672 18.5583 11.8084 17.0249C13.8251 15.6749 13.8251 13.4749 11.8084 12.1333C9.52506 10.6083 5.76672 10.6083 3.46672 12.1333Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2833 16.6667C15.8833 16.5417 16.45 16.3001 16.9167 15.9417C18.2167 14.9667 18.2167 13.3584 16.9167 12.3834C16.4583 12.0334 15.9 11.8001 15.3083 11.6667"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const EventIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 1.66602V4.16602"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 1.66602V4.16602"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.91602C16.1083 3.06602 17.5 4.12435 17.5 8.04102V13.191C17.5 16.6243 16.6667 18.341 12.5 18.341H7.5C3.33333 18.341 2.5 16.6243 2.5 13.191V8.04102C2.5 4.12435 3.89167 3.07435 6.66667 2.91602H13.3333Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2913 14.666H2.70801"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 6.875C8.97533 6.875 8.10866 7.43333 8.10866 8.51667C8.10866 9.03333 8.35033 9.425 8.71699 9.675C8.20866 9.975 7.91699 10.4583 7.91699 11.025C7.91699 12.0583 8.70866 12.7 10.0003 12.7C11.2837 12.7 12.0837 12.0583 12.0837 11.025C12.0837 10.4583 11.792 9.96667 11.2753 9.675C11.6503 9.41667 11.8837 9.03333 11.8837 8.51667C11.8837 7.43333 11.0253 6.875 10.0003 6.875ZM10.0003 9.24167C9.56699 9.24167 9.25033 8.98333 9.25033 8.575C9.25033 8.15833 9.56699 7.91667 10.0003 7.91667C10.4337 7.91667 10.7503 8.15833 10.7503 8.575C10.7503 8.98333 10.4337 9.24167 10.0003 9.24167ZM10.0003 11.6667C9.45033 11.6667 9.05033 11.3917 9.05033 10.8917C9.05033 10.3917 9.45033 10.125 10.0003 10.125C10.5503 10.125 10.9503 10.4 10.9503 10.8917C10.9503 11.3917 10.5503 11.6667 10.0003 11.6667Z"
        fill="#564A5A"
      />
    </svg>
  );
export const CommunitiesIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.917 4.45898C17.917 5.21732 17.5587 5.89232 17.0087 6.32565C16.6087 6.64232 16.1003 6.83398 15.542 6.83398C14.2253 6.83398 13.167 5.77565 13.167 4.45898C13.167 3.90065 13.3587 3.40065 13.6753 2.99232H13.6837C14.1087 2.44232 14.7837 2.08398 15.542 2.08398C16.8587 2.08398 17.917 3.14232 17.917 4.45898Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83301 4.45898C6.83301 5.77565 5.77467 6.83398 4.45801 6.83398C3.89967 6.83398 3.39967 6.64232 2.99134 6.32565C2.44134 5.89232 2.08301 5.21732 2.08301 4.45898C2.08301 3.14232 3.14134 2.08398 4.45801 2.08398C5.21634 2.08398 5.89134 2.44232 6.32467 2.99232C6.64134 3.40065 6.83301 3.90065 6.83301 4.45898Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.917 15.541C17.917 16.8577 16.8587 17.916 15.542 17.916C14.7837 17.916 14.1087 17.5577 13.6837 17.0077H13.6753C13.3587 16.6077 13.167 16.0993 13.167 15.541C13.167 14.2243 14.2253 13.166 15.542 13.166C16.1003 13.166 16.6003 13.3577 17.0087 13.6743V13.6827C17.5587 14.1077 17.917 14.7827 17.917 15.541Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83301 15.541C6.83301 16.0993 6.64134 16.5993 6.32467 17.0077C5.89134 17.566 5.21634 17.916 4.45801 17.916C3.14134 17.916 2.08301 16.8577 2.08301 15.541C2.08301 14.7827 2.44134 14.1077 2.99134 13.6827V13.6743C3.39134 13.3577 3.89967 13.166 4.45801 13.166C5.77467 13.166 6.83301 14.2243 6.83301 15.541Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9163 10.0007C17.9163 11.334 17.5913 12.5757 17.008 13.6757C16.608 13.359 16.0997 13.1673 15.5413 13.1673C14.2247 13.1673 13.1663 14.2257 13.1663 15.5423C13.1663 16.1007 13.358 16.6007 13.6747 17.009C12.5747 17.5923 11.333 17.9173 9.99967 17.9173C8.67467 17.9173 7.42467 17.5923 6.32467 17.009C6.64134 16.609 6.83301 16.1007 6.83301 15.5423C6.83301 14.2257 5.77467 13.1673 4.45801 13.1673C3.89967 13.1673 3.39967 13.359 2.99134 13.6757C2.40801 12.5757 2.08301 11.334 2.08301 10.0007C2.08301 8.67565 2.40801 7.42565 2.99134 6.32565C3.39967 6.64232 3.89967 6.83398 4.45801 6.83398C5.77467 6.83398 6.83301 5.77565 6.83301 4.45898C6.83301 3.90065 6.64134 3.40065 6.32467 2.99232C7.42467 2.40898 8.67467 2.08398 9.99967 2.08398C11.333 2.08398 12.5747 2.40898 13.6747 2.99232C13.358 3.39232 13.1663 3.90065 13.1663 4.45898C13.1663 5.77565 14.2247 6.83398 15.5413 6.83398C16.0997 6.83398 16.5997 6.64232 17.008 6.32565C17.5913 7.42565 17.9163 8.67565 17.9163 10.0007Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const ExploreIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 9.99935C18.3337 5.39935 14.6003 1.66602 10.0003 1.66602C5.40033 1.66602 1.66699 5.39935 1.66699 9.99935C1.66699 14.5993 5.40033 18.3327 10.0003 18.3327"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66667 2.5H7.5C5.875 7.36667 5.875 12.6333 7.5 17.5H6.66667"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 2.5C13.3083 4.93333 13.7167 7.46667 13.7167 10"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 13.3333V12.5C4.93333 13.3083 7.46667 13.7167 10 13.7167"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.5C7.36667 5.875 12.6333 5.875 17.5 7.5"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 17.8334C16.6394 17.8334 17.8333 16.6394 17.8333 15.1667C17.8333 13.6939 16.6394 12.5 15.1667 12.5C13.6939 12.5 12.5 13.6939 12.5 15.1667C12.5 16.6394 13.6939 17.8334 15.1667 17.8334Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 18.3333L17.5 17.5"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const PeopleIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0004 5.96602C14.9504 5.95769 14.8921 5.95769 14.8421 5.96602C13.6921 5.92435 12.7754 4.98268 12.7754 3.81602C12.7754 2.62435 13.7337 1.66602 14.9254 1.66602C16.1171 1.66602 17.0754 2.63268 17.0754 3.81602C17.0671 4.98268 16.1504 5.92435 15.0004 5.96602Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1422 12.0328C15.2839 12.2245 16.5422 12.0245 17.4255 11.4328C18.6005 10.6495 18.6005 9.36615 17.4255 8.58282C16.5339 7.99115 15.2589 7.79115 14.1172 7.99115"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97539 5.96602C5.02539 5.95769 5.08372 5.95769 5.13372 5.96602C6.28372 5.92435 7.20039 4.98268 7.20039 3.81602C7.20039 2.62435 6.24206 1.66602 5.05039 1.66602C3.85873 1.66602 2.90039 2.63268 2.90039 3.81602C2.90872 4.98268 3.82539 5.92435 4.97539 5.96602Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83353 12.0328C4.69186 12.2245 3.43353 12.0245 2.5502 11.4328C1.3752 10.6495 1.3752 9.36615 2.5502 8.58282C3.44186 7.99115 4.71686 7.79115 5.85853 7.99115"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99941 12.1926C9.94941 12.1842 9.89108 12.1842 9.84108 12.1926C8.69108 12.1509 7.77441 11.2092 7.77441 10.0426C7.77441 8.85091 8.73275 7.89258 9.92441 7.89258C11.1161 7.89258 12.0744 8.85925 12.0744 10.0426C12.0661 11.2092 11.1494 12.1592 9.99941 12.1926Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.57461 14.8168C6.39961 15.6001 6.39961 16.8835 7.57461 17.6668C8.90794 18.5585 11.0913 18.5585 12.4246 17.6668C13.5996 16.8835 13.5996 15.6001 12.4246 14.8168C11.0996 13.9335 8.90794 13.9335 7.57461 14.8168Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const WalletIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.27441 13.2318L13.2327 3.27344"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25098 15.2324L10.251 14.2324"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4941 12.9897L13.4858 10.998"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.00138 8.53258L8.53472 2.99925C10.3014 1.23258 11.1847 1.22425 12.9347 2.97425L17.0264 7.06591C18.7764 8.81591 18.7681 9.69925 17.0014 11.4659L11.4681 16.9992C9.70138 18.7659 8.81805 18.7742 7.06805 17.0242L2.97638 12.9326C1.22638 11.1826 1.22638 10.3076 3.00138 8.53258Z"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 18.332H18.3337"
        stroke="#564A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const AnalyticsIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66699 18.334H18.3337"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 3.33268V18.3327H11.875V3.33268C11.875 2.41602 11.5 1.66602 10.375 1.66602H9.625C8.5 1.66602 8.125 2.41602 8.125 3.33268Z"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 8.33268V18.3327H5.83333V8.33268C5.83333 7.41602 5.5 6.66602 4.5 6.66602H3.83333C2.83333 6.66602 2.5 7.41602 2.5 8.33268Z"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 12.5007V18.334H17.5003V12.5007C17.5003 11.584 17.167 10.834 16.167 10.834H15.5003C14.5003 10.834 14.167 11.584 14.167 12.5007Z"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
export const SettingsIcon = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 10.7339V9.26718C1.66699 8.40052 2.37533 7.68385 3.25033 7.68385C4.75866 7.68385 5.37533 6.61718 4.61699 5.30885C4.18366 4.55885 4.44199 3.58385 5.20033 3.15052L6.64199 2.32552C7.30033 1.93385 8.15033 2.16718 8.54199 2.82552L8.63366 2.98385C9.38366 4.29218 10.617 4.29218 11.3753 2.98385L11.467 2.82552C11.8587 2.16718 12.7087 1.93385 13.367 2.32552L14.8087 3.15052C15.567 3.58385 15.8253 4.55885 15.392 5.30885C14.6337 6.61718 15.2503 7.68385 16.7587 7.68385C17.6253 7.68385 18.342 8.39218 18.342 9.26718V10.7339C18.342 11.6005 17.6337 12.3172 16.7587 12.3172C15.2503 12.3172 14.6337 13.3838 15.392 14.6922C15.8253 15.4505 15.567 16.4172 14.8087 16.8505L13.367 17.6755C12.7087 18.0672 11.8587 17.8339 11.467 17.1755L11.3753 17.0172C10.6253 15.7089 9.39199 15.7089 8.63366 17.0172L8.54199 17.1755C8.15033 17.8339 7.30033 18.0672 6.64199 17.6755L5.20033 16.8505C4.44199 16.4172 4.18366 15.4422 4.61699 14.6922C5.37533 13.3838 4.75866 12.3172 3.25033 12.3172C2.37533 12.3172 1.66699 11.6005 1.66699 10.7339Z"
        stroke="#49404C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
